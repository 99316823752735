<template>
  <div>
    <TitleMore
      :title="['组织简介']"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard">
      <p style="
font-size: 20px;
font-weight: bold;
color: #555454;
line-height: 48px;text-align:center">{{introData.title}}</p>
      <TitleLine width="200px"></TitleLine>
      <div class="introInfo">
        <span>录入时间：{{introData.publishDate}}</span>
        <!-- <span>浏览次数：{{item.views}}次</span> -->
        <!-- <span>选择阅读字号</span>
        <span>[ 双击滚屏 ]</span> -->
      </div>
      <div
        class="detailContent"
        v-html="introData.text"
      ></div>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import TitleLine from "@components/TitleLine";
import apiUrls from "@api";
export default {
  data() {
    return {
      introData: {},
    };
  },

  components: {
    TitleMore,
    TitleLine,
  },

  mounted() {
    this.getIntro();
  },

  methods: {
    getIntro() {
      apiUrls.trainNum({ node: "zzgk_zzjj" }).then((res) => {
        this.introData = res.results.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.introInfo {
  width: 600px;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  span {
    font-size: 14px;
    color: #999999;
  }
}
.detailContent{
  margin-top:20px;
  line-height: 30px;
}
</style>